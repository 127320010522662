<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 m8 offset-m2 l6 offset-l3">
            <div class="card col s12 vert-offset-top-1 padding-top-half">

                <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                    <router-link
                            class="btn-small homeboy-charcoal-black white-text margin-right-half"
                            to="/login">
                        <i class="material-icons font-20">arrow_back</i>
                        </router-link>
                    Need help with your pin?
                    </div>
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                    <div class="row">
                        <span class="font-12 homeboy-charcoal-black-text">Do you have a reset OTP already? If yes, <router-link to="/pin/reset" class="underline homeboy-gold-text">click here to reset your pin</router-link></span>
                    </div>
                    <div class="row">
                        <div class="col s12 no-padding">
                            <div class="row col s12 m5 vert-offset-top-1">
                                <custom-select-component ref="country" v-model="selected_country" :options="countries"  title="Type to select your Country"></custom-select-component>
                            </div>
                            <div class="col s12 m7 vert-offset-bottom-half">
                                <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" required="required" minlength="6" maxlength="15"></input-component>
                            </div>

                        </div>

                        <div class="col s12 no-padding ">
                            <div class="col s12 m6 offset-m3 l6 offset-l3">
                                <a
                                        @click.prevent="sendRequest"
                                        class="btn btn-block homeboy-gold-text homeboy-charcoal-black"
                                >
                                    Send Request
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                </div>

            </div>
        </div>

</template>

<script>
    import http from "../../http-common"
    import { bus } from "../../main"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"
    import {mapState} from "vuex"

    export default {
        name: 'Forgot-Password',
        components:{
            InputComponent,CustomSelectComponent
        },
        data(){
            return{
                mobile:"",
                selected_country:""
            }
        },
        created(){
            console.log("LOGGING HERE");
            this.getCountries();
           /*
            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if(_data == "confirmSendOtp")
                {
                    console.log("Trigger confirm send otp");
                    this.changePin();
                }
                this.$store.dispatch('SET_ACTION', '');
            });*/
            //this.confirmPinChange();
        },
        mounted(){
            //this.$refs.country.initializeSelect();
            this.selected_country ="BW";
        },
        computed:{
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user,
                countries:state=>state.loyaltolocal.l2l_countries
            }),

        },
        methods:{
            confirmSendOTP: function()
            {
                console.log("Event Triggered: confirmPinChange");
                if(this.mobile !== "")
                {
                    this.$store.dispatch('SET_ACTION', 'confirmSendOtp');
                }
            },
            sendRequest:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: '/customers/password/reset/request',
                        data:{
                            msisdn:this.mobile,
                            country:this.selected_country,
                            source: this.$source
                        },
                        method: 'POST',
                        timeout: 30000
                    }).then
                    (resp => {

                        this.$store.dispatch('STOP_PROCESSING');
                        //this.customer_services = resp.data.data;
                        //console.log("DATA : "+resp.data.data);
                        this.$store.dispatch('GET_MESSAGES',['An otp was sent to your mobile number, Please use it to change your pin on the link above.']);

                        this.mobile = "";
                        this.selected_country ="BW";


                        resolve(resp)
                    })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response.data));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },
            getCountries:function () {
                this.$store.dispatch("GET_L2L_COUNTRIES_REQUEST");
            }

        }
    }
</script>
